import React, {
  ChangeEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  Box,
  Heading,
  VStack,
  Text,
  Link as ChakraLink,
  InputGroup,
  InputLeftElement,
  Input,
  Button,
} from '@chakra-ui/react';
import { graphql, Link, PageProps } from 'gatsby';
import { SearchIcon } from '@chakra-ui/icons';
import Fuse from 'fuse.js';

import Layout from '../layout';
import Head from '../components/head';
import Products, {
  ProductCategories as Categories,
} from '../components/products';
import EcommerceFAQs from '../components/faqs/ecommerce';
import type { ProductType, Category } from '../types';
import { debounce } from '../utils';

const LIMIT = 20;

export interface DataType {
  allProduct: {
    nodes: ProductType[];
  };
  allCategory: {
    nodes: Category[];
  };
}

const Store: React.FC<PageProps<DataType>> = ({ data }) => {
  const { allProduct, allCategory } = data;
  const { nodes: categories } = allCategory;

  const searchRef = useRef<HTMLInputElement>(null);

  const [category, setCategory] = useState<string | null>(
    null as unknown as string,
  );
  const [products, setProducts] = useState<ProductType[]>(allProduct.nodes);
  const [hasMore, setHasMore] = useState<boolean>(true);
  const [page, setPage] = useState<number>(1);

  const search = useCallback(
    (query: string | null, keys: string[]) => {
      if (!query) {
        return setProducts(allProduct.nodes);
      }

      const result = new Fuse(allProduct.nodes, {
        keys,
        threshold: 0.2,
      }).search(query);

      return setProducts(result.map((result) => result.item));
    },
    [allProduct.nodes],
  );

  useEffect(() => {
    if (searchRef?.current) searchRef.current.value = '';
    search(category, ['category']);
  }, [category, search, searchRef.current]);

  const handleSearch = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      debounce(search, 500)(
        event.target.value,
        ['name', 'manufacturer'].concat(
          typeof category === 'string' ? ['category'] : [],
        ),
      );
    },
    [search, category],
  );

  const paginatedProducts = useMemo(() => {
    setHasMore(page < Math.ceil(products.length / LIMIT));
    return [...products].splice(0, page * LIMIT + 1);
  }, [products, page, setHasMore]);

  return (
    <Layout headerColorScheme="dark">
      <Head title="Store - Jamestown" />

      <Box
        p="100px calc(var(--cp) + 1rem) 0"
        position="relative"
        align="center"
        height="260px"
        width="100%"
        _before={{
          top: 0,
          left: 0,
          zIndex: 0,
          bg: 'purple',
          width: '100%',
          content: '" "',
          height: '350px',
          position: 'absolute',
        }}
      />

      <Box
        top="100px"
        width="100%"
        color="#fff"
        align="center"
        position="absolute"
        padding="1.5rem calc(var(--cp) + 1rem) 2rem"
        display="grid"
        placeItems="center"
      >
        <Heading mb={10} size="xl">
          Our Store
        </Heading>

        <InputGroup
          width={{ lg: '600px', sm: '100%', base: '100%' }}
          boxSizing="border-box"
          size="lg"
        >
          <InputLeftElement
            pointerEvents="none"
            children={<SearchIcon color="gray.300" />}
          />

          <Input
            type="search"
            color="#fff"
            width="100%"
            ref={searchRef}
            borderRadius="3xl"
            onChange={handleSearch}
            placeholder="Search for Products by Names and Manufacturer"
          />
        </InputGroup>

        <Categories
          category={category}
          categories={categories}
          setCategory={setCategory}
        />
      </Box>

      {Boolean(paginatedProducts.length) && (
        <Products products={paginatedProducts} />
      )}

      {!paginatedProducts.length && (
        <Box w="100%" mx="auto" px="var(--cp)" py={10}>
          <Box
            px={6}
            py={6}
            bg="white"
            height="400px"
            display="grid"
            placeItems="center"
            position="relative"
            borderRadius="10px"
          >
            <Text size="md" color="purple">
              Oops! No products found 😰
            </Text>
          </Box>
        </Box>
      )}

      {hasMore && (
        <Box align="center" my={20} mt={5}>
          <Button
            size="lg"
            bg="purple"
            color="white"
            onClick={() => setPage((page) => page + 1)}
            _hover={{
              bg: 'purple',
            }}
          >
            Load More
          </Button>
        </Box>
      )}

      <Box
        bg="#eee"
        py="6rem"
        px={{ lg: 'calc(var(--cp) + 10rem)', base: 5 }}
        textAlign="center"
      >
        <VStack
          p="0 5px"
          width="500px"
          maxW="100%"
          sx={{ margin: '0 auto !important' }}
        >
          <Heading size="lg" color="purple">
            Frequently Asked Questions (FAQs)
          </Heading>
          <Text fontWeight={500}>
            Our focus is always on finding the best people to work with. Our bar
            is high, but you look ready to take on the challenge.
          </Text>
        </VStack>

        <Box my={10}>
          <EcommerceFAQs />
        </Box>

        <Text fontWeight={500}>
          How can we help?{' '}
          <ChakraLink to="/contact" href="/contact" as={Link}>
            Contact us
          </ChakraLink>
        </Text>
      </Box>
    </Layout>
  );
};

export const query = graphql`
  query ShopQuery {
    allProduct {
      nodes {
        manufacturer
        category
        id
        description
        name
        thumbnails
        slug
        variants {
          keys
          values
        }
      }
    }
    allCategory {
      nodes {
        name
        id
      }
    }
  }
`;

export default Store;
